import {
  BlockStack,
  Box,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  Text,
} from "@shopify/polaris";
import Sync from "../components/Sync";
import { useState } from "react";
import { getLastSyncTime, getPipelineLog, initiateDataSync } from "../utils/api";
import { calculateSyncPercentages } from "../utils/utils";
import { useLoaderData, useOutletContext } from "react-router-dom";

export const loader = async() => {
  const lastSyncTime = await getLastSyncTime();
  return {lastSyncTime};
};

const Settings = () => {
  const [syncPercentages, setSyncPercentages] = useState({
    pPercent: 0,
    cPercent: 0,
    oPercent: 0,
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const shop = useOutletContext();
  const loaderData = useLoaderData();
  const lastSyncTime = loaderData?.lastSyncTime;

  const handleSync = () => {
    let clear;
    setIsDisabled(true);
    initiateDataSync(shop, "incrementalSync").then(() => {
      setIsDisabled(false);
      clearInterval(clear);
    });

    clear = setInterval(() => {
      getPipelineLog(shop).then((log) => {
        if (log) {
          setSyncPercentages((prevSyncPercentages) =>
            calculateSyncPercentages(log, prevSyncPercentages)
          );
        }
      });
    }, 3000);
  };

  return (
    <InlineGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
      <Box
        as="section"
        paddingInlineStart={{ xs: 400, sm: 0 }}
        paddingInlineEnd={{ xs: 400, sm: 0 }}
      >
        <BlockStack gap="400">
          <Text as="h3" variant="headingMd">
            Sync Data
          </Text>
        </BlockStack>
      </Box>
      <Card padding="500">
        <BlockStack gap="600">
          <Sync syncPercentages={syncPercentages} lastSyncTime={lastSyncTime} />
          <InlineStack align="start">
            <Button
              variant="primary"
              onClick={handleSync}
              disabled={isDisabled}
              loading={isDisabled}
            >
              Sync Now
            </Button>
          </InlineStack>
        </BlockStack>
      </Card>
    </InlineGrid>
  );
};

export default Settings;
