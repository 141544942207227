// Object to keep track of the last known statuses of scripts
const lastKnownStatuses = {};

export const getShopCredentials = () => {
  try {
    const shopCredentials = localStorage.getItem("shopCredentials");
    // Check if the data exists
    if (shopCredentials) {
      return JSON.parse(shopCredentials);
    }
    return null;  // Return null if no credentials are found
  } catch (error) {
    console.error("Error retrieving shop credentials:", error);
    return null;  // Return null in case of an error
  }
};

export const updateShopCredentials = (newShopCredentials) => {
  try {
    // Ensure newShopCredentials is a valid object
    if (newShopCredentials && typeof newShopCredentials === "object") {
      let shopCredentials = localStorage.getItem("shopCredentials");

      // If shopCredentials already exists, merge with the new data
      if (shopCredentials) {
        shopCredentials = JSON.parse(shopCredentials);
        shopCredentials = { ...shopCredentials, ...newShopCredentials };
      } else {
        // If no existing credentials, initialize with newShopCredentials
        shopCredentials = { ...newShopCredentials };
      }

      // Store updated shop credentials back to localStorage
      localStorage.setItem("shopCredentials", JSON.stringify(shopCredentials));
    } else {
      console.warn("Invalid shop credentials provided for update.");
    }
  } catch (error) {
    console.error("Error updating shop credentials:", error);
  }
};


// Function to handle script execution statuses and trigger animations only for changed statuses
export function handleScriptExecutionStatuses(log) {
  // Check if log and events are available
  if (!log || !log.events) {
    console.error("Invalid log data");
    return null;
  }

  const events = log.events;

  // Loop through each event to check statuses
  for (const [scriptName, eventData] of Object.entries(events)) {
    // Initialize last known status if not already done
    if (!lastKnownStatuses[scriptName]) {
      lastKnownStatuses[scriptName] = { started: false, ended: false };
    }

    // Check if the script has transitioned from 'start' to 'end'
    const scriptStarted = eventData.status === "start" && eventData.start_time;
    const scriptEnded = eventData.status === "end" && eventData.end_time;

    //Check if sync_shopify script has started running
    if (scriptName === "sync_shopify" && scriptStarted && !scriptEnded) {
      return { scriptName, scriptStarted, scriptEnded };
    }

    // Only trigger animation if the script has just finished
    if (scriptEnded && !lastKnownStatuses[scriptName].ended) {
      // Update the last known statuses
      lastKnownStatuses[scriptName].started = scriptStarted;
      lastKnownStatuses[scriptName].ended = scriptEnded;
      return { scriptName, scriptStarted, scriptEnded };
    }
  }
  return null;
}

export function calculateSyncPercentages(log, currentSync) {
  // Initialize the values for each category, ensuring that both fields are present before using them
  const pTotal = log.shopifyProducts?.total_items;
  const pSynced = log.shopifyProducts?.synced_items;
  const cTotal = log.shopifyCustomers?.total_items;
  const cSynced = log.shopifyCustomers?.synced_items;
  const oTotal = log.shopifyOrders?.total_items;
  const oSynced = log.shopifyOrders?.synced_items;

  let pPercent;
  if (pTotal === 0 && pSynced === 0) {
    pPercent = 100; // All products are considered synced when both totals are 0
  } else if (pTotal > 0 && pSynced > 0) {
    pPercent = Math.ceil(
      (pSynced / pTotal) * 100
    ); // Calculate percentage if there are products
  } else {
    pPercent = 0; // Set to 0 if there's no total product information
  }

  let cPercent;
  if (cTotal === 0 && cSynced === 0) {
    cPercent = 100; // All customers are considered synced when both totals are 0
  } else if (cTotal > 0 && cSynced > 0) {
    cPercent = Math.ceil(
      (cSynced / cTotal) * 100
    ); // Calculate percentage if there are customers
  } else {
    cPercent = 0; // Set to 0 if there's no total customer information
  }

  let oPercent;
  if (oTotal === 0 && oSynced === 0) {
    oPercent = 100; // All orders are considered synced when both totals are 0
  } else if (oTotal > 0 && oSynced > 0) {
    oPercent = Math.ceil((oSynced / oTotal) * 100); // Calculate percentage if there are orders
  } else {
    oPercent = 0; // Set to 0 if there's no total order information
  }

  let result = {
    pPercent,
    cPercent,
    oPercent,
  };

  result.pPercent = Math.min(
    Math.max(
      currentSync.pPercent + 1,
      pPercent
    ),
    100
  );

  // Check if the previous resource has achieved 100% progress and increment the next by 2%
  if (pPercent === 100) {
    //Check if the actual progress is greater than the fake one, choose whichever has a greater value
    //Also, make sure the progress doesn't go above 100 in any case
    result.cPercent = Math.min(
      Math.max(
        currentSync.cPercent + 1,
        cPercent
      ),
      100
    );
  }

  if (cPercent === 100) {
    // Customers are fully synced, increment orders by 2%
    result.oPercent = Math.min(
      Math.max(
        currentSync.oPercent + 1,
        oPercent
      ),
      100
    );
  }
  return result;
}
