import { useEffect, useState } from "react";
import {
  BlockStack,
  Button,
  Card,
  InlineStack,
  Page,
  Text,
} from "@shopify/polaris";
import { useLocation, useNavigate } from "react-router-dom";

import CheckpointProgressBar from "../components/CheckpointProgressBar";
import image from "../assets/setup.png";
import { getPipelineLog, initiateDataSync } from "../utils/api";
import "./Onboarding.css";
import {
  calculateSyncPercentages,
  handleScriptExecutionStatuses,
} from "../utils/utils";
import Sync from "../components/Sync";

let clear;
export default function Onboarding() {
  const steps = [{ height: 55 }, { height: 185 }, { height: 0 }];
  const [currentStep, setCurrentStep] = useState(-1);
  const [showStartButton, setShowStartButton] = useState(false);
  const [syncPercentages, setSyncPercentages] = useState({
    pPercent: 0,
    cPercent: 0,
    oPercent: 0,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const shop = location.state.shop;

  useEffect(() => {
    (async function () {
      setCurrentStep((prevStep) => prevStep + 1);
      initiateDataSync(shop, "initialSync").then(() => {
        clearInterval(clear);
      });

      clear = setInterval(() => {
        getPipelineLog(shop).then((log) => {
          const result = handleScriptExecutionStatuses(log);
          if (result) {
            if (result.scriptName === "sync_shopify" && result.scriptStarted) {
              setSyncPercentages((prevSyncPercentages) =>
                calculateSyncPercentages(log, prevSyncPercentages)
              );
            } else {
              if (result.scriptName === "main" && result.scriptEnded) {
                setShowStartButton(true);
              }
              setCurrentStep((prevStep) => prevStep + 1);
              setSyncPercentages({
                pPercent: 100,
                cPercent: 100,
                oPercent: 100,
              });
            }
          }
        });
      }, 3000);
    })();
  }, [shop]);

  const handleClick = () => {
    if (clear) clearInterval(clear);
    navigate(`/home/dashboard${window.location.search}`);
  };

  return (
    <Page>
      <Card padding={{ lg: "800", xs: "400" }}>
        <InlineStack gap={{ lg: "1200", xs: "600" }} wrap={false}>
          <img src={image} alt="" style={{ width: "40%" }} />
          <BlockStack gap="600">
            <Text variant="headingMd">Data sync status</Text>
            <InlineStack wrap={false} gap={{ lg: "600", xs: "150" }}>
              <CheckpointProgressBar steps={steps} currentStep={currentStep} />
              <div className="container">
                <BlockStack gap="200">
                  <Text fontWeight="semibold" variant="bodyLg">
                    Install InsightsX
                  </Text>
                  <Text fontWeight="regular" tone="subdued">
                    InsightsX has been successfully installed and is connected
                    to Shopify.
                  </Text>
                </BlockStack>
                <Sync syncPercentages={syncPercentages} />
                <BlockStack gap="200">
                  <Text fontWeight="semibold" variant="bodyLg">
                    Generating Insights
                  </Text>
                  <Text fontWeight="regular" tone="subdued">
                    We are analyzing your data to generate your reports. This
                    could take a few hours. Once complete, you will be able to
                  </Text>
                </BlockStack>
                {showStartButton && (
                  <InlineStack align="start">
                    <Button
                      variant="primary"
                      size="large"
                      onClick={handleClick}
                    >
                      Start Exploring
                    </Button>
                  </InlineStack>
                )}
              </div>
            </InlineStack>
          </BlockStack>
        </InlineStack>
      </Card>
    </Page>
  );
}
