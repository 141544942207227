import { useState } from "react";
import { Bleed, BlockStack, Page, Tabs } from "@shopify/polaris";
import { json, Outlet, useLoaderData, useNavigate } from "react-router-dom";

import { tabs } from "../utils/data";
import { getShopCredentials } from "../utils/utils";

export const loader = async () => {
  const shopCredentials = getShopCredentials();
  const shopUrl = shopCredentials?.url;
  return json({ shopUrl });
};

export default function NavBar() {
  const loaderData = useLoaderData();
  const shop = loaderData?.shopUrl;
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();

  const handleSelect = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
    navigate("/home/" + tabs[selectedTabIndex].id + window.location.search);
  };

  return (
    <Page>
      <BlockStack gap="800">
        <Bleed marginInline="200">
          <Tabs tabs={tabs} selected={selected} onSelect={handleSelect} />
        </Bleed>
        <Outlet context={shop} />
      </BlockStack>
    </Page>
  );
}
