import React from "react";
import {
  Card,
  BlockStack,
  InlineStack,
  Text,
  Button,
} from "@shopify/polaris";

import star from "../assets/star.svg";
import warning from "../assets/warning.svg";
import reviewStar from "../assets/grey_star.svg";
import chart from "../assets/chart.png";

const Home = () => {
  return (
    <>
      <Card>
        <InlineStack align="space-between" blockAlign="center">
          <Text variant="bodyMd">
            We'd love to hear about your experience with our shopify app
          </Text>
          <InlineStack gap="200">
            <img
              src={reviewStar}
              alt="review-star"
              width="25"
              style={{ cursor: "pointer" }}
            />
            <img
              src={reviewStar}
              alt="review-star"
              width="25"
              style={{ cursor: "pointer" }}
            />
            <img
              src={reviewStar}
              alt="review-star"
              width="25"
              style={{ cursor: "pointer" }}
            />
            <img
              src={reviewStar}
              alt="review-star"
              width="25"
              style={{ cursor: "pointer" }}
            />
            <img
              src={reviewStar}
              alt="review-star"
              width="25"
              style={{ cursor: "pointer" }}
            />
          </InlineStack>
        </InlineStack>
      </Card>
      <BlockStack>
        <Text fontWeight="medium" variant="headingXl">
          Decor by the way
        </Text>
        <InlineStack align="space-between" blockAlign="center">
          <BlockStack gap="400">
            <InlineStack gap="200" blockAlign="center" align="start">
              <img src={star} alt="star" width="25" />
              <Text variant="bodyLg">Sale growth rate is greater than 20%</Text>
            </InlineStack>
            <InlineStack gap="200" blockAlign="center" align="start">
              <img src={star} alt="star" width="25" />
              <Text variant="bodyLg">Average order value is increasing</Text>
            </InlineStack>
            <InlineStack gap="200" blockAlign="center" align="start">
              <img src={warning} alt="star" width="25" />
              <Text variant="bodyLg">Repeat customer rate is less than 5%</Text>
            </InlineStack>
            <InlineStack gap="200" blockAlign="center" align="start">
              <img src={warning} alt="star" width="25" />
              <Text variant="bodyLg">CLTV has decreased this year</Text>
            </InlineStack>
          </BlockStack>
          <img src={chart} alt="chart" width="206" />
        </InlineStack>
      </BlockStack>
      <BlockStack gap="600">
        <InlineStack align="space-between">
          <Text variant="headingXl" fontWeight="medium">
            Top 3 Action Items for your team
          </Text>
          <Text variant="headingLg" fontWeight="regular">
            Total Score: &nbsp;&nbsp; 1 / 4
          </Text>
        </InlineStack>
        <Card>card ui</Card>
        <Card>card ui</Card>
        <Card>card ui</Card>
        <InlineStack align="end">
          <Button variant="primary" size="large">
            View All
          </Button>
        </InlineStack>
      </BlockStack>
    </>
  );
};

export default Home;
