import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { createApp } from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";

import { initApp } from "../utils/api";
import { appConfigs } from "../utils/data";

const Root = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const shop = urlParams.get("shop");
      const host = urlParams.get("host");

      const currentAppConfig = appConfigs.find(
        (config) => config.host === window.location.hostname
      );

      // Initialize Shopify App Bridge
      const app = createApp({
        apiKey: currentAppConfig.shopifyClientId,
        shopOrigin: shop,
        host: host,
        forceRedirect: true,
      });

      const token = await getSessionToken(app);
      const { onboardingCompleted } = await initApp(token);
      if (onboardingCompleted) navigate("/home/dashboard");
      else navigate("/onboarding", { state: { shop } });
    })();
  }, [navigate]);

  return;
};

export default Root;
