import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import Insights from "./pages/Insights";
import Onboarding from "./pages/Onboarding";
import Reports from "./pages/Reports";
import NavBar from "./components/NavBar";

import { loader as navbarLoader } from "./components/NavBar";
import { loader as insightsLoader } from "./pages/Insights";
import { loader as settingsLoader } from "./pages/Settings";
import Root from "./pages/Root";
import Subscriptions from "./pages/Subscriptions";
import Notifications from "./pages/Notifications";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
  },
  {
    path: "/home",
    element: <NavBar />,
    loader: navbarLoader,
    children: [
      {
        path: "settings",
        element: <Settings />,
        loader: settingsLoader,
      },
      {
        path: "dashboard",
        element: <Dashboard />
      },
      {
        path: "insights",
        element: <Insights />,
        loader: insightsLoader
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "subscriptions",
        element: <Subscriptions />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
    ],
  },
]);

const App = () => {
  return (
    <RouterProvider router={router}/>
  );
};

export default App;
