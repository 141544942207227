import React from "react";
import "./CheckpointProgressBar.css";
import checkMark from "../assets/checkMark.svg";

const CheckpointProgressBar = ({ steps, currentStep }) => {
  return (
    <div className="progress-container">
      {steps.map((step, index) => (
        <div
          style={{ "--step-height": `${step.height}px` }}
          className={`step ${index <= currentStep ? "completed" : ""}`}
          key={index}
        >
          <span className="icon">
            <img src={checkMark} alt="" />
          </span>
          {index < steps.length - 1 && (
            <div className={`line ${index <= currentStep ? "completed" : ""}`}>
              <div></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CheckpointProgressBar;
