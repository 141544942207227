export const appConfigs = [
  {
    host: "insightsx-prod.rapidquest.in",
    shopifyClientId: "dd21b8d3d9169a2ab17dcd87b62085a5",
  },
  {
    host: "insightsx-qa.rapidquest.in",
    shopifyClientId: "554e8aab4d6e8b4b6df762a172ef0716",
  },
  {
    host: "insightsx-dev.rapidquest.in",
    shopifyClientId: "70eabdac773f97bb288a8b3bd298087b",
  },
  {
    host: "garfish-resolved-gratefully.ngrok-free.app",
    shopifyClientId: "c3646825efdea845e1b02effdad647a8",
  },
  {
    host: "equal-shining-reindeer.ngrok-free.app",
    shopifyClientId: "d3eaa8474490a78c7744fe52726deb55",
  },
];

export const tabs = [
  {
    id: "dashboard",
    content: "Dashboard",
  },
  {
    id: "insights",
    content: "Insights",
  },
  {
    id: "reports",
    content: "Reports",
  },
  {
    id: "notifications",
    content: "Notifications",
  },
  {
    id: "subscriptions",
    content: "Subscriptions",
  },
  {
    id: "settings",
    content: "Settings",
  },
];
