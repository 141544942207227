import axios from "axios";
import { getShopCredentials, updateShopCredentials } from "./utils";


const axiosInstance = axios.create({
  baseURL: `https://${window.location.hostname}`
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Get the latest token
    const shopCredentials = getShopCredentials();
    const token = shopCredentials?.token;
    
    // If the token exists, set the Authorization header
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Do something with the request error
    return Promise.reject(error);
  }
);


export const initApp = async (sessionToken) => {
  const response = await axiosInstance.post(
    `/shopify/default/call/json/initApp`,
    {
      sessionToken: sessionToken,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (response.status === 200) {
    const token = response.data["insights_x_token"];
    const url = response.data["shop"];
    updateShopCredentials({token, url});
  } else {
    console.error("Couldnt initiate app", response.status);
  }

  const onboardingCompleted = response.data["onboardingCompleted"];
  return { onboardingCompleted };
};

export const getInsights = async () => {
  const response = await axiosInstance.post(
    `/shopify/default/call/json/getInsights`
  );

  const insights = response.data.insights;

  return insights;
};

export const getLastSyncTime = async () => {
  const response = await axiosInstance.post(
    `/shopify/default/call/json/get_last_sync_time`
  );

  if (!response.data.success) return "";

  const lastSyncTime = response.data.last_sync_time;

  // Convert the ISO timestamp to a Date object
  let date = new Date(lastSyncTime);

  // Options for formatting the time string
  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  // Format the date to the user's local time
  return date.toLocaleString(undefined, options);
};

export const initiateDataSync = async (shopUrl, syncType) => {
  await axiosInstance.post(
    `/shopify/default/call/json/initiateDataSync`,
    {
      shopUrl,
      syncType,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return null;
};

export const getPipelineLog = async (shopUrl) => {
  const response = await axiosInstance.post(
    `/shopify/default/call/json/getPipelineLog`,
    {
      shopUrl,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (response.data.error) return null;
  return response.data.log;
};
