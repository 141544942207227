import React, { useState } from "react";
import {
  Card,
  BlockStack,
  InlineStack,
  Text,
  ButtonGroup,
  Button,
  Badge,
} from "@shopify/polaris";
import { json, useLoaderData } from "react-router-dom";

import { getInsights } from "../utils/api";
import chart from "../assets/bar_chart.png";

export const loader = async() => {
  const insights = await getInsights();
  return json({insights});
};

let totalInsights = [];
export default function Insights() {
  const loaderData = useLoaderData();
  totalInsights = loaderData?.insights ?? [];

  const [activeButtonIndex, setActiveButtonIndex] = useState(-1);
  const [insights, setInsights] = useState(totalInsights);

  const filterInsights = (activeButtonIndex, timeFrame) => {
    setActiveButtonIndex(activeButtonIndex);
    const filterInsights = totalInsights.filter(
      (insight) => insight.duration === timeFrame
    );
    setInsights([...filterInsights]);
  };

  return (
    <>
      <InlineStack align="space-between" blockAlign="center">
        <Text fontWeight="medium" variant="headingMd">
          Category: Marketing
        </Text>
        <ButtonGroup variant="segmented">
          <Button
            pressed={activeButtonIndex === 0}
            onClick={() => filterInsights(0, "daily")}
          >
            Daily
          </Button>
          <Button
            pressed={activeButtonIndex === 1}
            onClick={() => filterInsights(1, "weekly")}
          >
            Week
          </Button>
          <Button
            pressed={activeButtonIndex === 2}
            onClick={() => filterInsights(2, "monthly")}
          >
            Month
          </Button>
          <Button
            pressed={activeButtonIndex === 3}
            onClick={() => filterInsights(3, "quarterly")}
          >
            Quarter
          </Button>
          <Button
            pressed={activeButtonIndex === 4}
            onClick={() => filterInsights(4, "yearly")}
          >
            Year
          </Button>
          <Button
            pressed={activeButtonIndex === 5}
            onClick={() => filterInsights(5, "lifetime")}
          >
            Lifetime
          </Button>
        </ButtonGroup>
      </InlineStack>
      {insights.length > 0 ?
        insights.map((insight) => {
          return (
            <Card key={insight._id}>
              <InlineStack gap="800" wrap={false}>
                <BlockStack gap="400" inlineAlign="start">
                  <Text variant="headingLg">{insight.results.title}</Text>
                  <Badge
                    tone={`${
                      insight.results.variables.high_or_low === "high"
                        ? "critical"
                        : "warning"
                    }`}
                  >
                    <Text variant="bodyMd" fontWeight="semibold">
                      {insight.results.variables.high_or_low === "high"
                        ? "High Impact"
                        : "Low Impact"}
                    </Text>
                  </Badge>
                  <Text fontWeight="regular" variant="bodyXl">
                    {insight.results.description}
                  </Text>
                </BlockStack>
                <img src={chart} alt="bar-chart" style={{ width: "46%" }} />
              </InlineStack>
            </Card> 
          );
        }): <span style={{textAlign: 'center'}}>No results</span>}
    </>
  );
};

