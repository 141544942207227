import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AppProvider } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <AppProvider
      i18n={{
        Polaris: {
          Common: {
            start: "Start",
            stop: "Stop",
          },
        },
      }}
    >
      <App />
    </AppProvider>
  // </React.StrictMode>
);
