import { BlockStack, Box, InlineStack, ProgressBar, Text } from "@shopify/polaris";

const Sync = ({ syncPercentages, lastSyncTime }) => {
  const pPercent =
    syncPercentages?.pPercent || 0;
  const cPercent =
    syncPercentages?.cPercent || 0;
  const oPercent = syncPercentages?.oPercent || 0;

  return (
    <BlockStack gap="200">
      <InlineStack align="space-between">
        <Text fontWeight="semibold" variant="bodyLg">
          Syncing Data
        </Text>
        {lastSyncTime && (
          <Box paddingBlockEnd="300">
            <Text variant="bodyMd" fontWeight="medium">
              {`Last synced at ${lastSyncTime}`}
            </Text>
          </Box>
        )}
      </InlineStack>
      <Text fontWeight="regular" tone="subdued">
        The initial import of your data from Shopify is now complete.
      </Text>
      <InlineStack align="space-between">
        <Text fontWeight="regular" tone="subdued">
          Products
        </Text>
        {pPercent > 0 && (
          <Text fontWeight="regular" tone="subdued">
            {`${pPercent}%`}
          </Text>
        )}
      </InlineStack>
      <ProgressBar progress={pPercent} size="small" />
      <InlineStack align="space-between">
        <Text fontWeight="regular" tone="subdued">
          Customers
        </Text>
        {cPercent > 0 && (
          <Text fontWeight="regular" tone="subdued">
            {`${cPercent}%`}
          </Text>
        )}
      </InlineStack>
      <ProgressBar progress={cPercent} size="small" />
      <InlineStack align="space-between">
        <Text fontWeight="regular" tone="subdued">
          Orders
        </Text>
        {oPercent > 0 && (
          <Text fontWeight="regular" tone="subdued">
            {`${oPercent}%`}
          </Text>
        )}
      </InlineStack>
      <ProgressBar progress={oPercent} size="small" />
    </BlockStack>
  );
};

export default Sync;
